import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Box, Grid, Snackbar, Alert } from '@mui/material';
import { ParamsPager } from '../paging';
import { getSearchParamsContext, SearchParamsAccess } from '../params';
import { BaseEntityService } from '../../services/data';
import { ApiFilter, ApiResponse, Filter, Nullable, PossibleAction } from '../../types/types';
import ServiceError from '../../services/errors';
import ErrorPageManager from '../Error/errorpagemanager';
import CustomButton, { CustomSwitchIconButton } from '../button/button';
import { SearchElementService } from '../../services/ddelements';

export interface ListUpdatedListener<T> { // TODO: rename
  listUpdated(apiResp: ApiResponse<Nullable<T[]>>) : void,
  listUpdateError(err: ServiceError) : void
}

export interface DataProvider<Entity> {
  updateList(listUpdatedListener: ListUpdatedListener<Entity>): void
}

export type ListRenderer<T> = (list: T[], totalCount: number, setUpdateList?: Dispatch<React.SetStateAction<boolean>>, checked?:Nullable<T[]>, setChecked?: Dispatch<React.SetStateAction<Nullable<T[]>>>) => ReactElement;

export type ListRendererMap<T> = {
  [key: string] : ListRenderer<T>
};

export class FilterParamsManager {
  searchParamsAccess: SearchParamsAccess;

  paramPrefix: string;

  constructor(searchParamsAccess: SearchParamsAccess, paramPrefix = 'f_') {
    this.searchParamsAccess = searchParamsAccess;
    this.paramPrefix = paramPrefix;
  }

  getFilter(filterId: string): Filter | undefined {
    const pageParam = this.paramPrefix+filterId;
    const value = this.searchParamsAccess.getParam(pageParam);
    if (value)  {
      return { id: filterId, value };
    }

    return undefined;
  }

  onFilterChanged(filterId: string, filterValue: string): void {
    const pageParam = this.paramPrefix+filterId;
    const value = this.searchParamsAccess.getParam(pageParam);

    value !== filterValue && this.searchParamsAccess.updateParam(pageParam, filterValue);
  }

  onFilterRemoved(filterId: string): void {
    const pageParam = this.paramPrefix+filterId;
    const value = this.searchParamsAccess.getParam(pageParam);

    value !== null && this.searchParamsAccess.updateParam(pageParam);
  }

  getFilters(): Filter[] {
    const filters: Filter[] = [];
    this.searchParamsAccess.searchParams.forEach((value, key) => {
      if (key.startsWith(this.paramPrefix)) {
        const filterId = key.substring(this.paramPrefix.length);
        filters.push({ id: filterId, value });
      }
    });

    return filters;
  }

  getFiltersWithPrefix(): Array<Filter> {
    const filters: Array<Filter> = [];
    this.searchParamsAccess.searchParams.forEach((value, key) => {
      if (key.startsWith(this.paramPrefix)) {
        filters.push({ id: key, value });
      }
    });

    return filters;
  }
}

export class EntityListDataProvider<Entity> implements DataProvider<Entity> {
  paramsPager: ParamsPager;

  sortParamsManager: FilterParamsManager;

  filterParamsManager: FilterParamsManager;

  entityService: BaseEntityService<Entity>;

  searchParamsAccess: SearchParamsAccess;

  constructor(searchParamsAccess: SearchParamsAccess, entityService: BaseEntityService<Entity>, itemsPerPageMin?: number, itemsPerPageMax?: number) {
    this.searchParamsAccess = searchParamsAccess;
    this.paramsPager = new ParamsPager(searchParamsAccess, itemsPerPageMin, itemsPerPageMax);
    this.filterParamsManager = new FilterParamsManager(searchParamsAccess);
    this.sortParamsManager = new FilterParamsManager(searchParamsAccess, 's_');
    this.entityService = entityService;
  }

  updateList(listUpdatedListener: ListUpdatedListener<Entity>): void {
    const page = this.paramsPager.getPage();
    const pageSize = this.paramsPager.getPageSize();
    const skip = page * pageSize;
    const filters = [...this.filterParamsManager.getFiltersWithPrefix(), ...this.sortParamsManager.getFiltersWithPrefix()];

    this.entityService.getEntityList(pageSize, skip, filters)
      .then((apiResp) => {
        listUpdatedListener.listUpdated(apiResp);
        // TODO: also update the totalItems (should be state)
      })
      .catch((err) => {
        listUpdatedListener.listUpdateError(ServiceError.ensureServiceError(err));
      });
  }
}

export class QuestionListDataProvider<Entity> implements DataProvider<Entity> {
  paramsPager: ParamsPager;

  filterParamsManager: FilterParamsManager;

  entityService: SearchElementService<Entity>;

  searchParamsAccess: SearchParamsAccess;

  ddId: string;

  constructor(ddId: string, searchParamsAccess: SearchParamsAccess, entityService: SearchElementService<Entity>, itemsPerPageMin?: number) {
    this.searchParamsAccess = searchParamsAccess;
    this.paramsPager = new ParamsPager(searchParamsAccess, itemsPerPageMin);
    this.filterParamsManager = new FilterParamsManager(searchParamsAccess);
    this.entityService = entityService;
    this.ddId = ddId;
  }

  updateList(listUpdatedListener: ListUpdatedListener<Entity>): void {
    const page = this.paramsPager.getPage();
    const pageSize = this.paramsPager.getPageSize();
    const skip = page * pageSize;
    const filters = this.filterParamsManager.getFilters();

    this.entityService.getElementList(this.ddId, filters, skip, pageSize)
      .then((apiResp) => {
        listUpdatedListener.listUpdated(apiResp);
        // TODO: also update the totalItems (should be state)
      })
      .catch((err) => {
        listUpdatedListener.listUpdateError(ServiceError.ensureServiceError(err));
      });
  }
}

export interface MultiModeListProps<T> {
  listDataProvider: EntityListDataProvider<T> | QuestionListDataProvider<T>,
  modes: string[], // TODO: create a DisplayMode class with id, icon, renderer, tooltip
  defaultMode?: string,
  renderers: ListRendererMap<T>,
  renderEmpty?: (totalCount?: number) => ReactElement,
  renderLoading?: (mode?: string) => ReactElement,
  filtersComponent?: (props: {filterParamsManager: FilterParamsManager, apiFilters?: ApiFilter[], entityType?: string}) => ReactElement,
  autoRefreshDelay?: number, // delay in seconds
  actionsService?: BaseEntityService<T>,
  entityType?: string
}

export function DisplayModeSelector(props: {default: string, onDisplayModeChanged: (value: string) => void}): ReactElement {
  const [displayMode, setDisplayMode] = useState<string>(props.default);

  useEffect( () => {
    props.onDisplayModeChanged(displayMode);
  }, [displayMode]);

  return (
    <>
      <CustomSwitchIconButton
        label1='cards'
        label2='table'
        label1Icon={<AppsIcon fontSize='small' />}
        label2Icon={<ListIcon fontSize='small' />}
        value={displayMode}
        setValue={setDisplayMode}
        small
      />
    </>
  );
}

function DefaultRenderList(altList: Array<unknown>, totalCount: number): ReactElement {
  return (
    <Box>{totalCount} items...</Box>
  );
}

function DefaultRenderEmpty(): ReactElement {
  return (
    <Box>No items...</Box>
  );
}

function DefaultRenderLoading(): ReactElement {
  return (
    <Box>Loading...</Box>
  );
}

function ActionsComponent<T>(props: {actionsService: BaseEntityService<T>, checked: Nullable<T[]>, setChecked: Dispatch<React.SetStateAction<Nullable<T[]>>>, setUpdate: Dispatch<React.SetStateAction<boolean>>}): ReactElement {
  const [editMode, setEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [possibleActions, setPossibleActions] = useState<Nullable<Array<PossibleAction>>>(null);

  useEffect(() => {
    props.actionsService.getPossibleGroupedActions()
      .then((apiResp) => {
        apiResp.data && setPossibleActions(apiResp.data.filter(item => item.enabled));
        props.setChecked([]);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${ServiceError.getErrorMsg(err)}`);
        setSnackbarOpen(true);
        props.setChecked(null);
      });
  }, [editMode]);

  const doAction = (action: PossibleAction) => {
    if (action.call && props.checked) {
      props.actionsService.makeGroupedActions(props.checked, action.call)
        .then(() => {
          setEditMode(false);
          props.setUpdate(true);
        })
        .catch((err) => {
          setErrorMsg(`${err.response?.status}: ${ServiceError.getErrorMsg(err)}`);
          setSnackbarOpen(true);
        });
    } else if (action.download) {
      setEditMode(false);
      window.open(process.env.REACT_APP_DEELIGENZ_API_BASE_URL + action.download.url, '_blank');
    }
  };

  return (
    <>
      {possibleActions &&
        <Grid item style={{ marginLeft: 'auto' }}>
          <Grid container spacing={2} justifyContent='flex-end'>
            {possibleActions?.map(action => (
              <Grid key={action.id} item>
                <CustomButton
                  variant='contained'
                  color='primary'
                  onClick={() => doAction(action)}
                  small
                  disabled={!props.checked || props.checked.length < 1}
                >
                  {action.label}
                </CustomButton>
              </Grid>
            ))}
          </Grid>
          <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
              {errorMsg}
            </Alert>
          </Snackbar>
        </Grid>
      }
    </>
  );
}

export function MultiModeList<T>({ listDataProvider, modes, defaultMode, renderers, renderEmpty, renderLoading, filtersComponent, actionsService, entityType/* , autoRefreshDelay */ } : MultiModeListProps<T>): ReactElement {
  const [displayMode, setDisplayMode] = React.useState<string>(defaultMode || modes[0]); // Use the first mode if no default
  const [listResp, setListResp] = React.useState<Nullable<ApiResponse<Nullable<T[]>>>>(null);
  const [checked, setChecked] = React.useState<Nullable<T[]>>(null);
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(false);

  const onDisplayModeChanged = (value: string) => {
    setDisplayMode(value);
  };

  const searchParamsAccess = getSearchParamsContext();

  const myListener : ListUpdatedListener<T> = {
    listUpdated(apiResp) : void {
      setListResp(apiResp);
    },
    listUpdateError(err: ServiceError) {
      setError(err);
    }
  };

  const updateData = () => {
    listDataProvider.updateList(myListener);
  };

  // Load the data
  useEffect(() => {
    if (update) {
      updateData();
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    setUpdate(true);
  }, [searchParamsAccess.searchParams]);

  // Setup auto refresh if requested
  // if (autoRefreshDelay) {
  //   useEffect(() => {
  //     const interval = setInterval(() => updateData(), autoRefreshDelay * 1000);
  //       return () => {
  //         clearInterval(interval);
  //       };
  //   }, []);
  // }

  const renderList = (list: T[], totalCount?: number, mySetUpdate?: Dispatch<React.SetStateAction<boolean>>) => {
    const count = totalCount || list.length;
    let listRenderer = renderers[displayMode];
    if (!listRenderer) {
      listRenderer = DefaultRenderList;
    }

    return listRenderer(list, count, mySetUpdate, checked, setChecked);
  };

  const renderContent = () => {
    if (listResp === null) {
      return renderLoading ? renderLoading(displayMode) : DefaultRenderLoading();
    }
    if ((listResp.data === null) || (listResp.data.length === 0)) {
      return renderEmpty ? renderEmpty(listResp.data?.length) : DefaultRenderEmpty();
    }

    return renderList(listResp.data, listResp.totalCount, setUpdate);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ flexWrap: 'nowrap', alignItems: 'center' }}>
            {modes && (modes.length > 1) &&
              <Grid item>
                <DisplayModeSelector default={displayMode} onDisplayModeChanged={onDisplayModeChanged} />
              </Grid>
            }
            {filtersComponent &&
              <>
                <Grid item>
                  <FilterListRoundedIcon />
                </Grid>
                <Grid item xs>
                  {filtersComponent({
                    filterParamsManager: listDataProvider.filterParamsManager,
                    apiFilters: listResp?.filters,
                    entityType // This is used for AumContributors Autocomplete because the behaviors change depending on the entityType
                  })}
                </Grid>
              </>
            }
          </Grid>
          { actionsService &&
            <ActionsComponent actionsService={actionsService} checked={checked} setChecked={setChecked} setUpdate={setUpdate} />
          }
        </Grid>
        <Grid item xs={12}>
          {error ? <ErrorPageManager error={error} /> : renderContent()}
        </Grid>
      </Grid>
    </>
  );
}
