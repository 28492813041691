import React, { ReactElement, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { Mandate } from '../../../types/types';
import CustomTable, { Column } from '../../../components/table/table';
import EntityLink from '../../../components/modularcomponents/entitylink/entitylink';
import { Rating2, Rating1 } from '../../duediligences/listing/datadisplay';
import { FilterParamsManager } from '../../../components/entity/list';
import SecureDisplay from '../../../components/authentification/securedisplay';
import { CustomIconButton } from '../../../components/button/button';
import { StyledMenu, useMenuStyle } from '../../../components/layout/navbar/navbar';
import { capitalizeFirstLetter } from '../../../utils/stringmanagement';
import { ReportHelper } from '../../../components/reports/pdfreports';
import checkRoles from '../../../components/authentification/checkRoles';
import TagIcon from '../../../components/aum/tagicon';
import NextReviews from '../../../components/nextreviews/nextReviews';

// const useStyles = makeStyles({
//   link: {
//     textDecoration: 'none',
//     color: 'inherit',
//     '&:hover': {
//       textDecoration: 'underline',
//     }
//   }
// });

const useStyles = makeStyles({
  rating: {
    display: 'inline-block'
  }
});

export function MandateTable(props: {list: Array<Mandate>, paramsSort?: FilterParamsManager}) : ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const menuClasses = useMenuStyle(theme);
  const { keycloak } = useKeycloak();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [dlOpen, setDlOpen] = useState<null | string>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setDlOpen(id);
  };

  const handleClose = () => {
    setDlOpen(null);
    setAnchorEl(null);
  };

  const columns: Array<Column> = [
    { id: 'name', label: 'Mandate Name', minWidth: 'min-content' },
    { id: 'assetMgrName', label: 'Asset Manager Name', minWidth: 'min-content' },
    // { id: 'assetClass', label: 'Asset Class', minWidth: 'min-content' },
    // { id: 'geography', label: 'Geography', minWidth: 'min-content' },
    // { id: 'subAssetClass', label: 'SubAsset Class', minWidth: 'min-content' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'nextReviews', label: 'Next Reviews', minWidth: 'min-content', align: 'center' },
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' }
  ];

  const columnsOnlyFundCenter: Array<Column> = [
    { id: 'name', label: 'Mandate Name', minWidth: 'min-content' },
    // { id:'country', label: 'Country', minWidth: 'min-content', align: 'center' },
    { id: 'rating1', label: 'AAIS Global Rating', minWidth: 'min-content', align: 'center' },
    { id: 'rating2', label: 'AAIS ESG Rating', minWidth: 'min-content', align: 'center' },
    // { id: 'gpsl', label: 'GPSL', minWidth: 'min-content', align: 'center' },
    { id: 'validationDate', label: 'Validation Date', minWidth: 'min-content', align: 'center' },
    { id: 'nextReviews', label: 'Next Reviews', minWidth: 'min-content', align: 'center' },
    { id: 'latestDdType', label: 'Latest Dd type', minWidth: 'min-content', align: 'center' },
    { id: 'reports', label: 'Reports', minWidth: 'min-content', align: 'right' }
  ];

  const rows = props.list.map((mandate: Mandate) => ({
    id: mandate._id,
    value: (checkRoles(['dd-viewer'], keycloak) ? columns :
      checkRoles(['fund-center-user', 'report-viewer-all'], keycloak) ? columnsOnlyFundCenter :
        columns).map(column => (
      <>
        {column.id === 'name' &&
              <Typography>
                <SecureDisplay
                  permissions='details'
                  optionalChild={<>{mandate.name}</>}
                >
                  <EntityLink type='mandate' id={mandate._id} name={mandate.name} center={column.align === 'center'} openInNewTabIcon />
                </SecureDisplay>
              </Typography>
        }
        {column.id === 'assetMgrName' && mandate.assetMgr &&
              <Typography>
                <EntityLink type='assetMgr' id={mandate.assetMgr._id} name={mandate.assetMgr.name} center={column.align === 'center'} openInNewTabIcon />
              </Typography>
        }
        {/* column.id === 'assetClass' && mandate.assetClass }
        {column.id === 'geography' && mandate.geography }
        {column.id === 'subAssetClass' && mandate.subAssetClass */}
        {column.id === 'latestDdType' && mandate.latestDdInfo &&
          <Typography>
            <SecureDisplay
              permissions='details'
              optionalChild={<>{mandate.latestDdInfo.type}</>}
            >
              <EntityLink type='dueDiligence' id={mandate.latestDdInfo._id} name={mandate.latestDdInfo.type} center={column.align === 'center'} openInNewTabIcon />
            </SecureDisplay>
          </Typography>
        }
        {column.id === 'validationDate' && mandate.latestDdInfo &&
          <Typography>
            {mandate.latestDdInfo.validationDate}
          </Typography>
        }
        {column.id === 'nextReviews' &&
          <NextReviews nextReviews={mandate.nextReviews} displayMinimalist reviewsStopped={mandate.hasReviewsStopped}/>
        }
        {column.id === 'rating1' &&
              <div className={classes.rating} >
                <Rating1 ratings={mandate.ratings} />
              </div>
        }
        {column.id === 'rating2' &&
              <div className={classes.rating} >
                <Rating2 ratings={mandate.ratings} />
              </div>
        }
        {column.id === 'gpsl' &&
          <TagIcon val={!!mandate.tags?.find(tag => tag.value === 'gpsl')} />
        }
        {column.id === 'reports' && mandate.reportDocuments &&
          <>
            <CustomIconButton variant='contained' onClick={e => handleClick(e, mandate._id)} small>
              <GetAppOutlinedIcon />
            </CustomIconButton>
            <StyledMenu
              disableScrollLock
              anchorEl={anchorEl}
              id='reportMenu'
              keepMounted
              open={mandate._id === dlOpen}
              onClose={handleClose}
            >
              <Grid container spacing={1}>
                {mandate.reportDocuments.map(report => (
                  report.tags.find(tag => tag.value === 'summary' || tag.value === 'mdd-review' || tag.value === 'mdd-analysis') &&
                  <Grid item xs={12} key={report._id}>
                    <MenuItem
                      className={menuClasses.menuItem}
                      onClick={() => {
                        ReportHelper.downloadPDFReportByDocId(report._id);
                        handleClose;
                      }}
                    >
                      {capitalizeFirstLetter(report.tags.find(tag => tag.family === 'ddReportType')?.value)}
                    </MenuItem>
                  </Grid>
                ))}
              </Grid>
            </StyledMenu>
          </>
        }
      </>
    ))
  }));

  return (
    <SecureDisplay
      permissions='details'
      optionalChild={
        <SecureDisplay
          permissions='reports'
          optionalChild={
            <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
          }
        >
          <CustomTable columns={columnsOnlyFundCenter} rows={rows} size='medium' paramsSort={props.paramsSort} />
        </SecureDisplay>
      }
    >
      <CustomTable columns={columns} rows={rows} size='medium' paramsSort={props.paramsSort} />
    </SecureDisplay>
  );
}

export default MandateTable;
