import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Grid, Snackbar, Typography, Alert, Tooltip } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { DataDueDiligence, DdDocument, Nullable, Tag, TagsFamily } from '../../../types/types';

// import { makeStyles } from '@mui/material/styles';
import CustomTable, { Column } from '../../../components/table/table';
import { DdDocumentUploadService, DdService } from '../../../services/dds';
// import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
// import { faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileAlt} from "@fortawesome/free-regular-svg-icons";
import { dataContext } from '../../../hooks/datatabs';
import ServiceError from '../../../services/errors';
import ErrorPageManager from '../../../components/Error/errorpagemanager';
import { CustomIconButton } from '../../../components/button/button';
import UploadFiles from '../../../components/upload/uploadfiles';
import Tags from '../../../components/tags/tags';
import PdfViewerDialog from '../../../components/pdfviewer/pdfmodal';
import ImgViewerDialog from '../../../components/pdfviewer/imagemodal';
import { bytesToSize } from '../../../utils/computations';
import DdAnalysisDocumentList from './ddanalysisdocumentlist';

// interface DDIcon {
//   type: string;
//   icon: FontAwesomeIconProps;
// }

// const entities: Array<DDIcon> = [
//   {type: 'pdf', icon: faFilePdf},
//   {type: 'word', icon: faFileWord},
//   {type: 'excel', icon: faFileExcel},
//   {type: 'powerpoint', icon: faFilePowerpoint},
//   {type: 'all', icon: faFileAlt},
// ];

// export function IconeType(props: {type: string})ReactElement
// {
//   const typeInfo = getTypeInfo(props.type);
//   return (
//     <>
//       {typeInfo && <typeInfo.icon />}
//     </>
//   );
// }

const isValidImg = (extension: string) => {
  const imgExtensions = ['jpg', 'jpeg', 'svg', 'bmp', 'png', 'gif', 'docx', 'wave', '.wav'];

  return imgExtensions.includes(extension);
};

export function DdDocumentTable(props: {list: Array<DdDocument>, ddId: string, update: () => void}) : ReactElement {
  const ddService = new DdService();
  const { t } = useTranslation();
  const [tagsEditMode, setTagsEditMode] = useState('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagsFamilies, setTagsFamilies] = useState<TagsFamily[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [update, setUpdate] = useState(true);
  const [list, setList] = useState(props.list);

  // const border = '2px solid white';
  // const classes = useStyles({ border: border });

  const downloadToken = undefined; // TODO: get a download token first...

  const columns: Array<Column> = [
    { id: 'filename', label: t('files.filename'), minWidth: 'min-content', align: 'left' },
    { id: 'contentType', label: t('files.fileType'), minWidth: 'min-content', align: 'left' },
    { id: 'size', label: t('files.size'), minWidth: 'max-content', align: 'left' },
    { id: 'actions', label: '', minWidth: 'min-content', align: 'right' }
  ];

  useEffect(() => {
    update && ddService.getTagsFamilies(props.ddId)
      .then((apiResp) => {
        apiResp.data && setTagsFamilies(apiResp.data);
        setUpdate(false);
        setTagsEditMode('');
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
        setUpdate(false);
      });
  }, [update]);

  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  const handleEditMode = (ddDocument: DdDocument) => {
    setTagsEditMode(ddDocument._id);
    setTags(ddDocument.tags);
  };

  const handleDownload = (docId: string) => {
    window.open(
      ddService.getDownloadURL(props.ddId, docId, downloadToken),
      '_blank'
    );
  };

  const handleDelete = (docId: string) => {
    ddService.deleteDocument(props.ddId, docId)
      .then(() => {
        props.update();
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const handleChangeTags = (docId: string) => {
    ddService.updateTags(props.ddId, docId, tags)
      .then(() => {
        props.update();
        setUpdate(true);
      })
      .catch((err) => {
        setErrorMsg(`${err.response?.status}: ${err.message}`);
        setSnackbarOpen(true);
      });
  };

  const rows = list.map((ddDocument: DdDocument) => ({
    id: ddDocument._id,
    value: columns.map(column => (
      <>
        {column.id === 'filename' &&
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>{ddDocument.fileInfo.filename}</Typography>
            </Grid>
            { (ddDocument.tags && ddDocument.tags.length > 0 || tagsEditMode === ddDocument._id) &&
              <Grid item xs={12}>
                <Tags
                  tagsFamilies={tagsFamilies}
                  tags={tagsEditMode === ddDocument._id ? tags : ddDocument.tags}
                  setTags={setTags}
                  editMode={tagsEditMode === ddDocument._id}
                  tagsDirection='horizontal'
                />
              </Grid>
            }
          </Grid>
        }
        {column.id === 'contentType' &&
          <div>
            {ddDocument.type}
          </div>
        }
        {column.id === 'size' &&
          <div style={{ whiteSpace: 'nowrap' }}>
            {bytesToSize(ddDocument.fileInfo.size)}
          </div>
        }
        {column.id === 'actions' &&
          <Grid container spacing={1} justifyContent='flex-end' style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              { tagsEditMode !== ddDocument._id ?
                <Tooltip title={t('btns.edit')} arrow>
                  <div>
                    <CustomIconButton
                      variant='text'
                      onClick={() => handleEditMode(ddDocument)}
                      small>
                      <EditRoundedIcon />
                    </CustomIconButton>
                  </div>
                </Tooltip> :
                <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
                  <Grid item xs>
                    <Tooltip title={t('btns.edit')} arrow>
                      <div>
                        <CustomIconButton variant='text'  sx={ { color: 'white', backgroundColor: 'success.light', "&:hover": { color: 'white', backgroundColor: 'success.light' } } } onClick={() => handleChangeTags(ddDocument._id)} small>
                          <CheckRoundedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <Tooltip title={t('btns.cancel')} arrow>
                      <div>
                        <CustomIconButton variant='text' color='default' onClick={() => setTagsEditMode('')} small>
                          <CloseRoundedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>

              }
            </Grid>
            {isValidImg(ddDocument.fileInfo.extension) &&
              <Grid item>
                <ImgViewerDialog documentId={ddDocument._id} documentTitle={ddDocument.fileInfo.filename} ddId={props.ddId} handleDownload={handleDownload}/>
              </Grid>
            }
            { ddDocument.fileInfo.extension === 'pdf' &&
              <Grid item>
                <PdfViewerDialog documentId={ddDocument._id} documentTitle={ddDocument.fileInfo.filename} ddId={props.ddId} handleDownload={handleDownload}/>
              </Grid>
            }
            { ddDocument.storage &&
              <>
                { tagsEditMode !== ddDocument._id ?
                  <Grid item>
                    <Tooltip title={t('btns.download')} arrow>
                      <div>
                        <CustomIconButton variant='contained' onClick={() => handleDownload(ddDocument._id)} small>
                          <GetAppOutlinedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid> :
                  <Grid item>
                    <Tooltip title={t('btns.delete')} arrow>
                      <div>
                        <CustomIconButton variant='contained' color='secondary'
                          onClick={() => handleDelete(ddDocument._id)} small>
                          <DeleteRoundedIcon />
                        </CustomIconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                }
              </>
            }
          </Grid>
        }
      </>
    ))
  }));

  return (
    <>
      <CustomTable columns={columns} rows={rows} size='medium' />
      <Snackbar onClose={() => setSnackbarOpen(false)} autoHideDuration={6000} open={snackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbarOpen(false)} style={{ marginTop: '20px' }} severity='error'>
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export function DdDocumentList(): ReactElement {
  const ddMenu = dataContext();
  const { t } = useTranslation();
  const dd: Nullable<DataDueDiligence> = ddMenu ? ddMenu.dueDiligence : null;
  const [listResp, setListResp] = React.useState<Array<DdDocument>>([]);
  const ddService = new DdService();
  const [error, setError] = useState<Nullable<ServiceError>>(null);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (dd && update) {
      ddService.getDocumentsForDd(dd._id)
        .then((apiResp) => {
          apiResp.data && setListResp(apiResp.data);
          setUpdate(false);
        })
        .catch((err) => {
          setError(ServiceError.ensureServiceError(err));
          setUpdate(false);
        });
    }
  }, [update]);

  return (
    <>
      {error ? <ErrorPageManager error={error} /> :
        dd &&
        <Grid container spacing={4} direction='column'>
          <Grid item>
            <UploadFiles service={new DdDocumentUploadService(dd._id)} callback={() => setUpdate(true)} multiple dispUploaded hideAfterFinish>
              <>
                {(listResp.length < 1) ?
                  <div>{t('dds.tabs.documents.noDocument')}</div> :
                  <DdDocumentTable list={listResp.filter(doc => doc.type !== 'answer')} ddId={dd._id} update={() => setUpdate(true)}/>
                }
              </>
            </UploadFiles>
          </Grid>
          <Grid item>
            <DdAnalysisDocumentList list={listResp.filter(doc => doc.type === 'answer')} ddId={dd._id}/>
          </Grid>
        </Grid>
      }
    </>
  );
}
